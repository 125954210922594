import React from 'react';
import Header from '../Header/Header';

const Contact = () => {
  return (
    <>
    <Header></Header>
    <div>
      <h3>Contact us</h3>
      <p> Information about us</p>
    </div>
    </>
  );
};

export default Contact;